import { lazy } from "react";
import { Navigate } from "react-router-dom";

const MainLayout = lazy(() => import("../pages/Layout/Main"));
const ClubLayout = lazy(() => import("../pages/Layout/Club"));
const UserLayout = lazy(() => import("../pages/Layout/User"));

const Home = lazy(() => import("../pages/Home/Home"));

const Auth = lazy(() => import("../pages/Auth/Auth"));
const NTU = lazy(() => import("../pages/Auth/NTU"));

const Welcome = lazy(() => import("../pages/Auth/Welcome"));
const VerifyEmail = lazy(() => import("../pages/Auth/VerifyEmail"));

const Clubs = lazy(() => import("../pages/Club/Clubs"));
const CreatorPod = lazy(() => import("../pages/Club/CreatorPod"));
const ClubDetail = lazy(() => import("../pages/Club/Detail"));
const IDCenter = lazy(() => import("../pages/MemberClub/IDCenter"));
const ClubInvite = lazy(() => import("../pages/MemberClub/Invite"));
const ClubAceptInvite = lazy(() => import("../pages/MemberClub/AcceptInvite"));
const Achievement = lazy(() => import("../pages/MemberClub/Achievement"));

const PostDetail = lazy(() => import("../pages/Home/PostDetail"));

const UserSettings = lazy(() => import("../pages/Settings/Settings"));
const UserProfile = lazy(() => import("../pages/Profile/Profile"));
const EditProfile = lazy(() => import("../pages/Profile/EditProfile"));
const ChangePassword = lazy(() => import("../pages/Settings/ChangePassword"));

const UserInventory = lazy(() => import("../pages/Profile/Inventory"));
const UserWallet = lazy(() => import("../pages/Wallet/Wallet"));
const BuyCredit = lazy(() => import("../pages/Wallet/BuyCredit"));
const RedeemHistory = lazy(() => import("../pages/Profile/RedeemHistory"));

const Missions = lazy(() => import("../pages/Mission/Missions"));
const MissionDetail = lazy(() => import("../pages/Mission/MissionDetail"));
const PlayMission = lazy(() => import("../pages/Mission/PlayMission"));

const Market = lazy(() => import("../pages/Market/Market"));
const Gacha = lazy(() => import("../pages/Market/Gacha"));

const ItemQR = lazy(() => import("../pages/Sample/ItemQR"));

const Station = lazy(() => import("../pages/Station/Station"));

const CreatorClub = lazy(() => import("../pages/Creator/Creator"));
const CreateClub = lazy(() => import("../pages/Creator/CreateClub"));
const CreateClubChatAI = lazy(() => import("../pages/Creator/ChatAI"));
const ClubSettings = lazy(() => import("../pages/Creator/ClubSettings"));
const ClubHome = lazy(() => import("../pages/Creator/ClubHome"));
const ClubMission = lazy(() => import("../pages/Creator/ClubMission"));
const ClubPost = lazy(() => import("../pages/Creator/ClubPost"));
const ClubStation = lazy(() => import("../pages/Creator/ClubStation"));
const ClubCreateModule = lazy(() => import("../pages/CreatorModules/Module"));

const DiscordHandler = lazy(() => import("../pages/Mission/DiscordHandler"));

function getRoutes() {
	const superchargeRoutes = [
		{
			path: "/auth",
			element: <Auth />,
		},
		{
			path: "/welcome",
			element: <Welcome />,
		},
		{
			path: "/verify-email",
			element: <VerifyEmail />,
		},
		{
			path: "/NTU",
			element: <NTU />,
		},
		{
			path: "/item-qr",
			element: <ItemQR />,
		},
		{
			path: "/discord",
			element: <DiscordHandler />,
		},
		{
			path: "/",
			element: <MainLayout />,
			children: [
				{
					path: "/",
					element: <Navigate to={"/home"} />,
				},
				{
					path: "/home",
					element: <Home />,
				},
				{
					path: "/missions",
					element: <Missions />,
				},
				{
					path: "/clubs",
					element: <Clubs />,
				},
			],
		},
		{
			path: "/club",
			element: <ClubLayout />,
			children: [
				{
					path: ":id",
					element: <ClubDetail />,
				},
				{
					path: "post-detail/:id",
					element: <PostDetail />,
				},
				{
					path: ":id/id-center",
					element: <IDCenter />,
				},
				{
					path: ":id/invite",
					element: <ClubInvite />,
				},
				{
					path: "invite/:code",
					element: <ClubAceptInvite />,
				},
				{
					path: "mission-detail/:id",
					element: <MissionDetail />,
				},
				{
					path: "play-mission/:id",
					element: <PlayMission />,
				},
				{
					path: ":id/market",
					element: <Market />,
				},
				{
					path: "gacha/:id",
					element: <Gacha />,
				},
				{
					path: ":id/achievement",
					element: <Achievement />,
				},
				{
					path: "station/:id",
					element: <Station />,
				},
				{
					path: ":id/settings",
					element: <ClubSettings />,
				},
				{
					path: ":id/home",
					element: <ClubHome />,
				},
				{
					path: ":id/missions",
					element: <ClubMission />,
				},
				{
					path: ":id/stations",
					element: <ClubStation />,
				},
				{
					path: ":id/posts",
					element: <ClubPost />,
				},
				{
					path: ":id/module",
					element: <ClubCreateModule />,
				},
			],
		},
		{
			path: "/user",
			element: <UserLayout />,
			children: [
				{
					path: "settings",
					element: <UserSettings />,
				},
				{
					path: "creatorpod",
					element: <CreatorPod />,
				},
				{
					path: "profile",
					element: <UserProfile />,
				},

				{
					path: "editprofile",
					element: <EditProfile />,
				},
				{
					path: "inventory",
					element: <UserInventory />,
				},
				{
					path: "wallet",
					element: <UserWallet />,
				},
				{
					path: "buy-credit",
					element: <BuyCredit />,
				},
				{
					path: "change-password",
					element: <ChangePassword />,
				},
				{
					path: "redeem-history",
					element: <RedeemHistory />,
				},
				{
					path: "creator-club",
					element: <CreatorClub />,
				},
				{
					path: "create-club",
					element: <CreateClub />,
				},
				{
					path: "create-club-ai",
					element: <CreateClubChatAI />,
				},
			],
		},
	];

	return superchargeRoutes;
}

export default {
	getRoutes: getRoutes,
};
