import { createContext, useContext } from "react";
export type MissionContent = {
	mission?: {};
	requirements?: [];
	setRequirments?: (req: []) => void;
};
export const MissionContext = createContext<MissionContent>({
	mission: {},
	requirements: [], // set a default value
	setRequirments: () => {},
});

export const useGlobalContext = () => useContext(MissionContext);
