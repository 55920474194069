import { useEffect, useState } from "react";
import { BrowserRouter, HashRouter, useRoutes } from "react-router-dom";
import Routes from "./routes/Routes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import auth from "./services/auth";
import { ThemeProvider } from "./components/theme-provider";
import { MissionContext } from "./hooks/useMission";
import { DialogProvider } from "./components/dialog-context";


function App() {
	const { user } = useAuth();

	useEffect(() => {
		const app = initializeApp(firebaseConfig);
	}, []);

	if (user) {
		auth.setAuthorizationToken(user?.sctoken);
	}

	let routing = useRoutes(Routes.getRoutes());

	const firebaseConfig = {
		apiKey: "AIzaSyDQ-2sxodmuqY7bb_AG0xF6Ehy_-z-v470",
		authDomain: "supercharge-7452e.firebaseapp.com",
		projectId: "supercharge-7452e",
		storageBucket: "supercharge-7452e.appspot.com",
		messagingSenderId: "270343597800",
		appId: "1:270343597800:web:3393bed999e9c5892ee35d",
		measurementId: "G-3G31P0QTN8",
	};

	return (
		<div className="flex items-center justify-center h-screen">
			<main className="w-full   h-full">
				<DialogProvider>
					<ThemeProvider
						defaultTheme="system"
						storageKey="spc-ui-theme">
						<MissionContext.Provider value={{}}>
							{routing}
						</MissionContext.Provider>
					</ThemeProvider>
				</DialogProvider>
			</main>
		</div>
	);
}

export default App;
