import axios from "axios";
import config from "./config";
var md5 = require("md5");

async function login(email, password) {
	let body = {
		email: email,
		password: md5(password),
	};
	try {
		let response = await axios.post("/auth/system", body);
		let result = response.data;
		return result;
	} catch (error) {
		return error;
	}
}

async function register(email, password) {
	let body = {
		email: email,
		password: md5(password),
	};
	try {
		let response = await axios.post("/auth/system/register", body);
		let result = response.data;
		return result;
	} catch (error) {
		return error;
	}
}

async function validateEmail(email) {
	let body = {
		email: email,
	};
	try {
		let response = await axios.post("/auth/system/validateEmail", body);
		let result = response.data;
		return result;
	} catch (error) {
		return error;
	}
}

async function validateUsername(user_name) {
	let body = {
		user_name: user_name,
	};
	try {
		let response = await axios.post("/auth/system/validateUsername", body);
		let result = response.data;
		return result;
	} catch (error) {
		return error;
	}
}

function setAuthorizationToken(token) {
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
}

async function firebaseAuthenticate(token) {
	let body = {
		firebase_token: token,
	};
	try {
		let response = await axios.post("/auth/firebase", body);
		let result = response.data;
		// if (result?.data) {
		//     setAuthorizationToken(result?.data.token);
		// }
		return result;
	} catch (error) {
		return error;
	}
}

async function discordAuthenticate(code, redirect_uri) {
	let body = {
		code: code,
		redirect_uri: redirect_uri,
	};
	try {
		let response = await axios.post("/auth/discord", body);
		let result = response.data;
		// if (result?.data) {
		//     setAuthorizationToken(result?.data.token);
		// }
		return result;
	} catch (error) {
		return error;
	}
}

async function twitterLogin(redirect_url) {
	let body = {
		redirect_url: redirect_url,
	};
	try {
		let response = await axios.post("/public/getTwitterAuthLink", body);
		let result = response.data;
		return result;
	} catch (error) {
		return error;
	}
}

async function verifyTwitter(oauth_token, oauth_verifier, oauth_token_secret) {
	let body = {
		oauth_token: oauth_token,
		oauth_verifier: oauth_verifier,
		oauth_token_secret: oauth_token_secret,
	};
	try {
		let response = await axios.post("/public/verifyTwitter", body);
		let result = response.data;
		return result;
	} catch (error) {
		return error;
	}
}

export default {
	login: login,
	setAuthorizationToken: setAuthorizationToken,
	firebaseAuthenticate: firebaseAuthenticate,
	discordAuthenticate: discordAuthenticate,
	register: register,
	validateEmail: validateEmail,
	validateUsername: validateUsername,
	twitterLogin: twitterLogin,
	verifyTwitter: verifyTwitter,
};
