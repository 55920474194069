import React, { createContext, useContext, useState, ReactNode } from "react";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";

interface DialogContextType {
	openDialog: (content: ReactNode) => void;
	closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const useDialog = () => {
	const context = useContext(DialogContext);
	if (!context) {
		throw new Error("useDialog must be used within a DialogProvider");
	}
	return context;
};

export const DialogProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
	const [open, setOpen] = useState<boolean | false>(false);

	const openDialog = (content: ReactNode) => {
		setOpen(true);
		setDialogContent(content);
	};

	const closeDialog = () => {
		setOpen(false);
		setDialogContent(null);
	};

	return (
		<DialogContext.Provider value={{ openDialog, closeDialog }}>
			{children}
			{dialogContent && (
				<Dialog open={open} onOpenChange={setOpen}>
					<DialogContent className="dialog-content-inner">
						{dialogContent}
					</DialogContent>
					
				</Dialog>
			)}
		</DialogContext.Provider>
	);
};
